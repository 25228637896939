<dsh-home *transloco="let t; scope: 'app'; read: 'app'">
    <div
        style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 48px;
            height: 100%;
            flex: 1;
        "
    >
        <div>
            <dsh-sections *ngIf="bootstrapped$ | async; else spinner"></dsh-sections>
            <ng-template #spinner>
                <div fxFlexAlign="center" fxLayout="row">
                    <dsh-spinner style="margin: auto"></dsh-spinner>
                </div>
            </ng-template>
        </div>
        <div *ngIf="isDev" style="display: flex; justify-content: center">
            <div
                [matMenuTriggerFor]="menu"
                class="dsh-body-2"
                style="cursor: pointer; display: flex; gap: 8px; align-items: center"
            >
                <dsh-bi icon="globe" size="sm"></dsh-bi>
                <!-- t(language.en) -->
                <!-- t(language.ru) -->
                {{ t('language.' + languageService.active) }}
            </div>
            <mat-menu #menu="matMenu">
                <button
                    *ngFor="let language of languages"
                    mat-menu-item
                    (click)="setLanguage(language)"
                >
                    {{ t('language.' + language) }}
                </button>
            </mat-menu>
        </div>
    </div>
</dsh-home>
