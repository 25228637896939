<ng-template cdk-portal>
    <div
        [ngStyle]="{ margin: offset, marginLeft: position === 'left' ? 0 : offset }"
        class="dsh-dropdown-wrapper"
    >
        <div [@openClose]="state$ | async" (@openClose.done)="animationDone$.next($event)">
            <div
                *ngIf="hasArrow"
                [style.left]="triangleLeftOffset"
                class="dsh-dropdown-triangle"
            ></div>
            <div class="dsh-dropdown">
                <ng-container *ngTemplateOutlet="contentTemplateRef"></ng-container>
            </div>
        </div>
    </div>
</ng-template>
