<dsh-dropdown-actions
    *transloco="let t; scope: 'components'; read: 'components.actionbar.user'"
    fxLayout="column"
    fxLayoutGap="24px"
>
    <ng-container>
        <dsh-menu-item
            *ngIf="activeOrg$ | async as activeOrg"
            class="dsh-body-2"
            (click)="toActiveOrg(activeOrg.id)"
            >{{ activeOrg.name }}</dsh-menu-item
        >
        <dsh-menu-item (click)="selectActiveOrg()">{{ t('selectActiveOrg') }}</dsh-menu-item>
        <dsh-menu-item (click)="openOrgList()">{{ t('orgList') }}</dsh-menu-item>
    </ng-container>
    <mat-divider></mat-divider>
    <span class="dsh-body-2">{{ username }}</span>
    <dsh-menu-item
        *ngFor="let linkConfig of userLinksConfig$ | async"
        (click)="openBlank(linkConfig.href)"
    >
        {{ linkConfig.title }}
    </dsh-menu-item>
    <dsh-menu-item (click)="logout()">{{ t('logout') }}</dsh-menu-item>
</dsh-dropdown-actions>
