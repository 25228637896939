<dsh-filter
    *transloco="let t; scope: 'components'; read: 'components.refundStatusFilter'"
    [active]="isActive"
    [activeLabel]="savedValue | refundStatusLabel | async"
    [content]="content"
    [label]="t('label')"
    (clear)="clear()"
    (save)="save()"
>
</dsh-filter>
<ng-template #content>
    <dsh-refund-status-field [formControl]="control"></dsh-refund-status-field>
</ng-template>
