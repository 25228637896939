<div fxLayout="column">
    <div *ngFor="let control of formControl.controls; let idx = index" fxLayout fxLayoutGap="24px">
        <mat-form-field fxFlex>
            <mat-label>{{ label }}</mat-label>
            <input [formControl]="control" autocomplete="off" matInput type="text" />
        </mat-form-field>
        <dsh-bi
            *ngIf="formControl.controls.length > 1 || formControl.controls[0].value"
            class="remove-icon action-icon"
            icon="x"
            size="lg"
            (click)="removeControl(idx)"
        ></dsh-bi>
    </div>
    <dsh-bi
        class="action-icon"
        fxFlexAlign="end"
        icon="plus"
        size="lg"
        (click)="addControl()"
    ></dsh-bi>
</div>
