<dsh-filter
    *transloco="let t; scope: 'components'; read: 'components.invoicesFilter'"
    [active]="isActive"
    [activeLabel]="t('activeLabel') | listLabel: savedValue : 0"
    [content]="content"
    [label]="t('label')"
    (clear)="clear()"
    (save)="save()"
>
</dsh-filter>
<ng-template #content
    ><dsh-invoices-field [formControl]="control"></dsh-invoices-field
></ng-template>
