<dsh-base-dialog
    *transloco="let t; scope: 'components'; read: 'components.select-active-organization-dialog'"
    [title]="t('title')"
    (cancel)="close()"
>
    <div fxLayout="column" fxLayoutGap="8px">
        <dsh-limited-panel
            [hasMore]="(isLoading$ | async) === false && (hasMore$ | async)"
            (showMore)="showMore()"
        >
            <mat-radio-group
                [(ngModel)]="selectedOrganization"
                fxLayout="column"
                fxLayoutGap="24px"
            >
                <mat-radio-button
                    *ngFor="let organization of organizations$ | async"
                    [value]="organization"
                >
                    {{ organization.name }}
                </mat-radio-button>
            </mat-radio-group>
        </dsh-limited-panel>
        <dsh-spinner *ngIf="isLoading$ | async" fxFlexAlign="center"></dsh-spinner>
    </div>
    <ng-container dshBaseDialogActions>
        <button
            [disabled]="
                !selectedOrganization ||
                selectedOrganization.id === (contextOrganization$ | async)?.id
            "
            color="accent"
            dsh-button
            (click)="confirm()"
        >
            {{ t('confirm') }}
        </button>
    </ng-container>
</dsh-base-dialog>
