<div class="dsh-user-dropdown" fxLayout="column" fxLayoutGap="4px">
    <div fxLayout fxLayoutAlign=" center" fxLayoutGap="8px">
        <div class="dsh-body-2 username">{{ username }}</div>
        <dsh-bi
            [@rotate]="expanded ? 'expanded' : 'collapsed'"
            icon="chevron-down"
            size="sm"
        ></dsh-bi>
    </div>
    <div *ngIf="orgName$ | async" class="dsh-caption organization">{{ orgName$ | async }}</div>
</div>
