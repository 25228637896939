<mat-drawer-container class="dsh-mobile-grid">
    <mat-drawer [autoFocus]="false" class="dsh-mobile-grid-drawer" mode="over">
        <div class="dsh-mobile-grid-drawer-wrapper" fxLayout="column">
            <div class="dsh-mobile-grid-drawer-actions" fxLayout="row" fxLayoutAlign="end center">
                <dsh-bi
                    class="dsh-mobile-grid-toggle-button"
                    icon="x"
                    size="lg"
                    (click)="closeSideNav()"
                ></dsh-bi>
            </div>
            <dsh-mobile-menu fxFlex (menuItemSelected)="closeSideNav()"></dsh-mobile-menu>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="dsh-mobile-grid-content" fxLayout="column" fxLayoutGap="24px">
            <div
                class="dsh-mobile-grid-content-actions"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="24px"
            >
                <dsh-bi
                    class="dsh-mobile-grid-toggle-button"
                    icon="list"
                    size="lg"
                    (click)="openSideNav()"
                ></dsh-bi>
                <dsh-brand></dsh-brand>
            </div>
            <ng-content></ng-content>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
