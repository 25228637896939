<ng-container *transloco="let t; scope: 'components'; read: 'components.identityFilter'">
    <dsh-filter
        [active]="isActive"
        [activeLabel]="t('activeLabel', { name: (identity$ | async)?.name || '#' + savedValue })"
        [content]="content"
        [label]="t('label')"
        (clear)="clear()"
        (save)="save()"
    ></dsh-filter>
    <ng-template #content>
        <dsh-identity-field [formControl]="control"></dsh-identity-field>
    </ng-template>
</ng-container>
