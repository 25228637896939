<dsh-base-dialog [title]="data.label" (cancel)="save()">
    <ng-container *ngTemplateOutlet="data.content"></ng-container>
    <ng-container
        *transloco="let t; scope: 'components'; read: 'components.shared'"
        dshBaseDialogActions
    >
        <button *ngIf="!data.noClearButton" color="accent" dsh-text-button (click)="clear()">
            {{ t('clear') }}
        </button>
        <button color="accent" dsh-button (click)="save()">
            {{ t('save') }}
        </button>
    </ng-container>
</dsh-base-dialog>
