<div [ngClass]="{ 'button-wrapper-disabled': disabled }" class="button-wrapper">
    <div
        *ngIf="(isMobile$ | async) === false"
        [dshDropdownTriggerFor]="dropdown"
        class="dropdown-trigger"
    ></div>
    <dsh-filter-button [active]="active" [disabled]="disabled" (click)="open()">
        {{ active ? activeLabel : label }}
    </dsh-filter-button>
</div>

<dsh-dropdown
    #dropdown="dshDropdown"
    hasArrow="false"
    offset="8px 0 0"
    position="left"
    width="360px"
    (closed)="save.emit()"
>
    <ng-template>
        <dsh-filter-content
            [noClearButton]="noClearButton"
            (clear)="clear.emit()"
            (save)="dropdown.close()"
        >
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </dsh-filter-content>
    </ng-template>
</dsh-dropdown>
