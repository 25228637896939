<div
    *ngIf="routerNavigationEnd$ | async"
    style="min-height: 100vh; min-height: 100dvh; display: flex; flex-direction: column"
>
    <ng-container *ngTemplateOutlet="(isXSmallSmall$ | async) ? mobile : laptop"></ng-container>
</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<ng-template #mobile>
    <dsh-mobile-grid>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </dsh-mobile-grid>
</ng-template>

<ng-template #laptop>
    <dsh-laptop-grid style="flex: 1">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </dsh-laptop-grid>
</ng-template>
