<div class="dsh-toolbar" fxLayout fxLayoutAlign="center center" fxLayoutGap="24px">
    <dsh-brand fxFlex fxLayoutAlign="start"></dsh-brand>
    <div fxFlex fxLayoutAlign="center">
        <nav [tabPanel]="tabPanel" class="dsh-top-tab-nav-bar" mat-tab-nav-bar>
            <a
                #rla="routerLinkActive"
                *ngFor="let link of sectionLinks$ | async"
                [active]="rla.isActive"
                [routerLink]="link.path"
                [routerLinkActiveOptions]="{ exact: link?.exact }"
                mat-tab-link
                routerLinkActive
            >
                {{ link.label }}
            </a>
        </nav>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    </div>
    <dsh-actionbar fxFlex fxLayoutAlign="end"></dsh-actionbar>
</div>
