<dsh-filter
    *transloco="let t; scope: 'components'; read: 'components.currencyFilter'"
    [active]="isActive"
    [activeLabel]="activeLabel$ | async"
    [content]="content"
    [label]="t('label')"
    noClearButton
    (clear)="clear()"
    (save)="save()"
>
</dsh-filter>
<ng-template #content>
    <dsh-currency-field [currencies]="currencies" [formControl]="control"></dsh-currency-field>
</ng-template>
