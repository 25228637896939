<div
    *transloco="let t; scope: 'components'; read: 'components.shared'"
    fxLayout="column"
    fxLayoutGap="24px"
>
    <ng-content></ng-content>
    <div *ngIf="hasMore" class="dsh-limited-panel-show-more dsh-body-1" (click)="showMoreItems()">
        {{ t('showMore') }}
    </div>
</div>
