<div
    class="dsh-actions"
    fxFlex="grow"
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign="space-between"
    fxLayoutGap="24px"
>
    <ng-content></ng-content>
</div>
